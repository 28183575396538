/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything

import $ from 'jquery';
global.$ = global.jQuery = window.$ = window.jQuery = $; // Set jQuery as global
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all.js';


// or you can include specific pieces
 require('bootstrap/js/dist/tooltip');
 require('bootstrap/js/dist/popover');
 require('datatables.net-bs5');

$(document).ready(function() {
 $('#tbc tfoot th').each( function () {
  var title = $(this).text();
  $(this).html( '<input class="form-control" type="text" placeholder="'+title+'" />' );
 } );

 var tableCompteurs = $('#tbc').dataTable({
  "pageLength": 25,
  "language": {
   "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
  },
  initComplete: function () {
   // Apply the search
   this.api().columns().every(function () {
    var that = this;
    $('input', this.footer()).on('keyup change clear', function () {
     if (that.search() !== this.value) {
      that
          .search(this.value)
          .draw();
     }
    });
   });
  }
 });

 var tableAires = $('#tbAires').dataTable({
  "pageLength": 25,
  "language": {
   "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
  },
  initComplete: function () {
   // Apply the search
   this.api().columns().every(function () {
    var that = this;
    $('input', this.footer()).on('keyup change clear', function () {
     if (that.search() !== this.value) {
      that
          .search(this.value)
          .draw();
     }
    });
   });
  }
 });

 var tableIsmas = $('#tbIsmas').dataTable({
  "pageLength": 25,
  "language": {
   "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
  },
  initComplete: function () {
   // Apply the search
   this.api().columns().every(function () {
    var that = this;
    $('input', this.footer()).on('keyup change clear', function () {
     if (that.search() !== this.value) {
      that
          .search(this.value)
          .draw();
     }
    });
   });
  }
 });

 var tableEmplacements = $('#tbEmplacements').dataTable({
  "pageLength": 25,
  "language": {
   "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
  },
  initComplete: function () {
   // Apply the search
   this.api().columns().every(function () {
    var that = this;
    $('input', this.footer()).on('keyup change clear', function () {
     if (that.search() !== this.value) {
      that
          .search(this.value)
          .draw();
     }
    });
   });
  }
 });

 var tableIsmas = $('#tbIsmasHist').dataTable({
    "pageLength": 25,
    "order" : [[1, 'desc']],
    "language": {
     "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
    },
    initComplete: function () {
     // Apply the search
     this.api().columns().every(function () {
      var that = this;
      $('input', this.footer()).on('keyup change clear', function () {
       if (that.search() !== this.value) {
        that
            .search(this.value)
            .draw();
       }
      });
     });
    }
   });

});